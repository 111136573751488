import React from 'react';
import './SearchByRouteButton.css';
import MapIcon from '@mui/icons-material/Map';

const SearchByRouteButton = ({ history }) => {
  return (
    <button onClick={() => history.push('/rs')}>
      <span class="box">
        Search by route <MapIcon className="map-icon" />
      </span>
    </button>
  );
};

export default SearchByRouteButton;
